import React, { useContext } from "react";
import axios from "axios";
import storage from "../storage/storage";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { productContext } from "../context/ProductContext";
import Header2 from "../components/Header2";
import Swal from "sweetalert2";
import '../estilos/Header.css';
import '../App.css';

function Session(){
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [resp, setResp] = useState('');
    const navigate =useNavigate();

    const {endpoint} = useContext(productContext);
    const show_alerta = (msj,icon) =>{
        Swal.fire({ title:msj, icon:icon, buttonsStyling:true});
    }

    const inisesion = async (e)=> {
        e.preventDefault();
        let res = '';
        await axios.post(endpoint, 
            {email: email, password: password})
            .then((response) => {
                console.log(response.data.name);
                console.log(response.data.access_token);
                storage.set('authToken', response.data.access_token);
                storage.set('name', response.data.name);
                storage.set('idUser', response.data.idUser);
                storage.set('imagen', response.data.imagen);
                storage.set('whats', response.data.whats);
                setResp(response.data);
                show_alerta(response.data.msg, 'success');
                navigate('/perfil');
            }).catch((errors) => {
                res = errors.response.data;
                show_alerta(res.msg, 'error');
            });
    }

    return (
        <>
        <Header2 mensaje={'sesion'}/>
        <div className="container-fluid vh-100 fondo d-flex align-items-center justify-content-center text-center">
            <div className="container vh-100 text-center">
                <h2 className="mt-3 text-white">Iniciar Sesión</h2>
                <div className={resp.msg ? 'alert alert-success' : ''}><p>{resp.msg}</p></div>
                <form className="w-100  p-3" onSubmit={ inisesion }>
                    <div className="form-floating mb-3">
                        <input type="email" className="form-control" id="email" value={email} onChange= { (e)=> setEmail(e.target.value)} required/>
                        <label >Email</label>
                    </div>
                    <div className="form-floating mb-3">
                        <input type="password" className="form-control" id="password"  value={password} onChange= { (e)=> setPassword(e.target.value)} required/>
                        <label>Password</label>
                    </div>
                    <button className="btn btn-primary mt-3 px-4" type="submit">Iniciar</button>
                </form>
                <div className="arrow-left2">
                    <Link to='/' className="enlace">
                        <i className="bi bi-arrow-left text-white"> Atrás</i>
                    </Link> 
                </div>
                
            </div>
                   
        </div>
       </> 
    );
}

export default Session;