
import './App.css';
import StateCompo from './context/StateCompo';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './home/Home';
import Session from './session/Session';
import Registro from './registro/Registro';
import Profile from './profile/Profile';
import MenuEditable from './menuEditable/MenuEditable';
import EditMenu from './menuEditable/EditMenu';
import AgregarNuevo from './menuEditable/AgregarNuevo';
import MenuClientes from './menuClientes/MenuClientes';
import Cart from './cart/Cart';
import ProtectedRoute from './utilies/ProtectedRoute';


function App() {
  return (   
    <StateCompo>
      <BrowserRouter>
        <Routes>
            <Route path='/' element={ <Home />} />
            <Route path='/iniciar-sesion' element={ <Session />} />
            <Route path='/registrarme' element={ <Registro />} />
            <Route element={<ProtectedRoute />}>
              <Route path='/perfil' element={ <Profile />} />
              <Route path='/menu' element={<MenuEditable /> } />
              <Route path='/editMenu' element={<EditMenu /> } />
              <Route path='/agregarPlatillo' element={<AgregarNuevo /> } />
            </Route>   
            <Route path='/menuClientes/:id' element={<MenuClientes /> } />
            <Route path='/cart' element={<Cart /> } />
        </Routes>
      </BrowserRouter>
    </StateCompo>
  );
}

export default App;
