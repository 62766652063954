import React, { useContext, useEffect, useState } from "react";
import { productContext } from "../context/ProductContext";
import storage from "../storage/storage";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import '../App.css';
import './menuEditable.css';
import Swal from "sweetalert2";

function EditMenu( props ){

    const {endpointEditPlatillo, datosPlatillo} = useContext(productContext);
    const [name, setName] = useState('');
    const [descripcion, setDescripcion] = useState('');
    const [precio, setPrecio] = useState('');
    const [imagen, setImagen] = useState(null);

    const handleImagenChange = (e) =>{
        setImagen(e.target.files[0]);
    }

    const show_alerta = (msj,icon) =>{
        Swal.fire({ title:msj, icon:icon, buttonsStyling:true});
    }

    function mostrarProducto(){
        setName(datosPlatillo.name);
        setDescripcion(datosPlatillo.descripcion);
        setPrecio(datosPlatillo.precio);
        setImagen(datosPlatillo.imagen);
    }

    useEffect(()=>{
        mostrarProducto();
    }, []);

    const guardarPlatillo = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('name', name);
        formData.append('descripcion', descripcion);
        formData.append('precio', precio);
        formData.append('imagen', imagen);
        formData.append('id', datosPlatillo.id);

        console.log(datosPlatillo.id);
        let res = '';
        await axios.post(endpointEditPlatillo + '/' + storage.get('idUser'),
            formData,
            { headers: { 
                'Content-Type': 'multipart/form-data',
                'Authorization' : 'Bearer ' + storage.get('authToken')}}
            )
            .then((response) => {
                console.log(response.data);
                if(response.data.status == 0){
                show_alerta(res.msg, 'error');

                }else{
                show_alerta(response.data.msg, 'success');
                }
                //navigate('/');
            }).catch((errors) => {
                res = errors.response.data;
                show_alerta(res.msg, 'error');
            });
    }

    return (
        <>
        <Header mensaje={'sesion'}/>
        <div className="d-flex justify-content-center text-white">
            <h4 className="mt-2">Editar platillo</h4>
        </div> 
        <form className="w-100  p-3" onSubmit={guardarPlatillo}>
            <div className="form-floating mb-3">
                <input type="text" className="form-control text-muted" id="name" value={name} onChange= { (e)=> setName(e.target.value)} />
                <label >Nombre</label>
            </div>
            <div className="form-floating mb-3">
                <input type="text" className="form-control" id="descricion" value={ descripcion } onChange= { (e)=> setDescripcion(e.target.value)} />
                <label >Descripción</label>
            </div>
            <div className="form-floating mb-3">
                <input type="number" className="form-control" id="precio" value={ precio } onChange= { (e)=> setPrecio(e.target.value)} />
                <label >Precio</label>
            </div>
            <div className="form-floating mb-3">
                <input type="file" className="form-control" id="imagen" accept="image/*" onChange={handleImagenChange} />
                <label >Imagen</label>
            </div>
            <div className="d-flex justify-content-around">
                <button className="btn btn-primary mt-3 px-4" type="submit">Guardar</button>
                <Link to={'/menu'} className="link2 mt-4">
                    <i className="bi bi-arrow-left text-primari"> Atrás</i>
                </Link> 
            </div>
            
        </form>
        </>
    );

}
export default EditMenu;
