import React, { useEffect } from "react";
import Header from "../components/Header";
import astronauta from "./astronauta.png";
import storage from "../storage/storage";
import { useNavigate } from "react-router-dom";
import '../App.css';



function Home() {
    const navigate =useNavigate();
    useEffect(()=>{
        if(storage.get('authToken')){
            navigate('/perfil');
        }
    }, []);

    return (
       <>
        <Header />
        <div className="container-fluid vh-100 fondo d-flex align-items-center justify-content-center text-center">
            <div className="row justify-content-center">
                <img src={ astronauta } className="img-fluid astronauta"/>
                <span className="mt-4 lead text-white">Servicios web para todos.</span>
            </div>
        </div>
       </> 

    );
}

export default Home;