import React, { useContext, useState, useEffect } from "react";
import { productContext } from "../context/ProductContext";
import axios from "axios";
import storage from "../storage/storage";
import Header from "../components/Header";
import Servicios from "./Servicios";
import Swal from "sweetalert2";
import '../App.css';
import './profile.css';
import Editar from "./Editar";
import CambiarPass from "./CambiarPass";
import FotoPerfil from "./FotoPerfil";

function Profile(){

    const {endpointVerFoto} = useContext(productContext);
    const [servic, setServic] = useState(true);
    const [editar, setEditar] = useState(false);
    const [pass, setPass] = useState(false);
    const [perfil, setPerfil] = useState(false);
    const [fotoPerfil, setFotoPerfil] = useState('');

    const show_alerta = (msj,icon) =>{
        Swal.fire({ title:msj, icon:icon, buttonsStyling:true});
    }

    const verFotoPerfil = async () => {
        let res = "";
        await axios.get(endpointVerFoto + '/' + storage.get('idUser'),
        { headers: { 'Authorization' : 'Bearer ' + storage.get('authToken')}}
        )
        .then((response) => {
            console.log(response.data);
            if(response.data.status === 0){
            show_alerta(res.msg, 'error');

            }else{
                setFotoPerfil(response.data.imagen);
            //show_alerta(response.data.msg, 'success');
            }
            //navigate('/');
        }).catch((errors) => {
            res = errors.response.data;
            show_alerta(res.msg, 'error');
        });
    }

    useEffect(()=>{
        verFotoPerfil();
    }, []);

    return(
        <>
        <Header mensaje={'sesion'}/>
        <div className="vh-100 fondo">
            <div className="contenedor-perfil">
                <div className="prop-perfil">
                    <div className="contenedor-img-perfil mt-3"  onClick={()=> {setServic(false); setEditar(false); setPass(false); setPerfil(true)}}>
                        <img src={"https://portafoliomars.xyz/storage/perfiles/"+ fotoPerfil} alt="logo" />
                    </div>
                    <div className="mt-3 datos-perfil">
                        <p className={servic ? 'seleccionado' : ''} onClick={()=> {setServic(true); setPass(false); setEditar(false); setPerfil(false)}}>Servicios</p>
                        <p className={editar ? 'seleccionado' : ''} onClick={()=> {setServic(false); setPass(false); setEditar(true); setPerfil(false)}}>Editar</p>
                        <p className={pass ? 'seleccionado' : ''} onClick={()=> {setServic(false); setEditar(false); setPass(true); setPerfil(false)}}>Cambiar Contraseña</p>
                    </div>       
                </div>
                {servic && <Servicios />}
                {editar && <Editar />}
                {pass && <CambiarPass />}
                {perfil && <FotoPerfil />}              


            </div>      
        </div>
        </>
    );
}

export default Profile;
