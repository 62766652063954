import React, { useContext, useState} from "react";
import axios from "axios";
import { productContext } from "../context/ProductContext";
import storage from "../storage/storage";
import Swal from "sweetalert2";
import '../App.css';
import './profile.css';

function FotoPerfil(){
    const {endpointFotoPerfil} = useContext(productContext);
    const [foto, setFoto] = useState(null);
    const handleImagenChange = (e) =>{
        setFoto(e.target.files[0]);
    }

    const show_alerta = (msj,icon) =>{
        Swal.fire({ title:msj, icon:icon, buttonsStyling:true});
    }


    const guardarFoto = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('imagen', foto);

        let res = '';
        await axios.post(endpointFotoPerfil + '/' + storage.get('idUser'),
            formData,
            { headers: { 'Authorization' : 'Bearer ' + storage.get('authToken')}}
            )
            .then((response) => {
                console.log(response.data);
                if(response.data.status === 0){
                show_alerta(res.msg, 'error');

                }else{
                show_alerta(response.data.msg, 'success');
                }
                //navigate('/');
            }).catch((errors) => {
                res = errors.response.data;
                show_alerta(res.msg, 'error');
            });
    }

    return (
        <>
            <div className="servicios">
                <div className="sub-servicios">
                    <div className="d-flex justify-content-center text-white">
                        <h5>Cambiar foto de perfil</h5>
                    </div>
                    <form className="w-100  p-3" onSubmit={guardarFoto}>
                    <div className="form-floating mb-3">
                        <input type="file" className="form-control" id="imagen" accept="image/*" onChange={handleImagenChange} required/>
                        <label >Foto de Perfil</label>
                    </div>
                        <button className="btn btn-primary mt-3 px-4" type="submit">Guardar</button>
                    </form>
                </div>
            </div>
        </>
    );
}

export default FotoPerfil;