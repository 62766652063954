import { Outlet, RedirectFunction, useNavigate } from "react-router-dom";
import storage from "../storage/storage";
import Home from "../home/Home";

const ProtectedRoute = () =>{
    const navigate =useNavigate();

    if(storage.get('authToken')){
        return <Outlet /> 
        
    }else{
        return <Home />;    
    }
}

export default ProtectedRoute;