import React from "react";

import { Link } from "react-router-dom";
import storage from "../storage/storage";
import '../estilos/Header.css';
import '../App.css';
import logoCweb from "./images/logo_cweb.png";



function Header2( props ) {

    return(
        <header>
            <nav className="">
                <div className="navbar navPrincipal p-2">
                    <Link to={'/'}>
                        <img src={ logoCweb } alt="Logo..." className="logoCweb"/>
                    </Link>
                    <div><i className={storage.get('name') ? 'bi bi-person m-2' : ''}></i>{ storage.get('name') }</div>
                    <div className="d-flex h-100">
                        <Link to='/iniciar-sesion' className="enlace">
                            <div className={props.mensaje=='sesion' ? '' : 'm-1'}>{ props.mensaje=='sesion' ? '' :(<p className="i-Sesion">Iniciar Sesión</p>) }</div>
                        </Link>
                        <Link to='/registrarme' className="enlace">
                            <div className={props.mensaje=='registro' ? '' : 'm-1'}>{ props.mensaje=='registro' ? '' :(<p className="i-Sesion">Registrarme</p>) }</div>
                        </Link>
                    </div>                 
                </div>

            </nav>
        </header>
    );
}

export default Header2;