import React, { useContext, useEffect, useState } from "react";
import { productContext } from "../context/ProductContext";
import storage from "../storage/storage";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import '../App.css';
import './menuEditable.css';
import Swal from "sweetalert2";


function MenuEditable(){
    const {endpointMostrarMenu, datosPlatillo, setDatosPlatillo, endpointBorrarPlato} = useContext(productContext);
    const [listMenu, setListMenu] = useState([]);
    const navigate =useNavigate();


    const show_alerta = (msj,icon) =>{
        Swal.fire({ title:msj, icon:icon, buttonsStyling:true});
    }

    const mostrarMenu = async (e)=>{
        let res = '';
        await axios.get(endpointMostrarMenu+`/${storage.get('idUser')}`, 
        {headers: {'Authorization' : 'Bearer ' + storage.get('authToken')}})
        .then((response)=>{
            console.log(response + 'respuesta 1');
            setListMenu(response.data);
        }).catch((errors) => {
            res = errors.response.data;
            show_alerta(res.msg, 'error');
        });
    }

    const borrarPlato = async (e)=>{
        let res = '';
        await axios.delete(endpointBorrarPlato+`/${storage.get('idUser')}`, 
        {headers: {'Authorization' : 'Bearer ' + storage.get('authToken')}})
        .then((response)=>{
            console.log(response + 'respuesta 1');
            show_alerta(response.data.msg, 'success');
            mostrarMenu();
        }).catch((errors) => {
            res = errors.response.data;
            show_alerta(res.msg, 'error');
        });
    }

    useEffect(()=>{
        mostrarMenu();
    }, []);

    function editarPlato(lmenu){
        setDatosPlatillo(lmenu);
        navigate('/editMenu');
    }

    return(
    <>
        <Header mensaje={'sesion'}/>
        <div className="f-menu">
            <div className="contenedorMenu">
                <div className="d-flex justify-content-around">
                    <Link to={'/agregarPlatillo'}>
                        <button title="agregar platillo" className="btn btn-primary mb-3 px-4">agregar</button>
                    </Link>
                   <Link to={'/perfil'} className="link2">
                        <i className="bi bi-arrow-left text-primari"> Atrás</i>
                    </Link> 
                </div>
    
                {
                    listMenu.map((lmenu) => (
                        <div className="itemMenu" key={crypto.randomUUID().toString()}>
                            <div className="imgMenu">
                                <img src={"https://portafoliomars.xyz/storage/platillos/"+lmenu.imagen} alt="platillo" />
                            </div>
                            <div className="descPlato w-100">
                                <div className="w-100">
                                    <h5>{ lmenu.name }</h5>
                                    <p>{ lmenu.descripcion }</p>
                                    <h5>{'$ '+lmenu.precio }</h5>
                                </div>       
                            </div> 
                            {/* <button className="btnPedir"><i className="bi bi-cart"></i></button> */}
                            <div>
                                <button onClick={() => (editarPlato(lmenu))} className="m-2 btn btn-warning"><i className="bi bi-pencil-square "></i></button>
                                <button onClick={() => (borrarPlato(lmenu))} className="m-2 btn btn-danger"><i className="bi bi-trash"></i></button> 
                            </div>                
                        </div> 
                    ))
                }
             
            </div>
        </div>
    </>
    );
}

export default MenuEditable;