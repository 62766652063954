import React, { useContext, useEffect, useState } from "react";
import { productContext } from "../context/ProductContext";
import axios from "axios";
import {useNavigate } from "react-router-dom";
import Header3 from "../components/Header3";
import '../App.css';
import '../menuEditable/menuEditable.css';
import Swal from "sweetalert2";


function MenuClientes( props ){
    
    const {endpointMenuClientes, endpointObtenerUser, cart, setCart, imagen, name, setImagen, setName, setUrlCliente, suma, whats, setWhats} = useContext(productContext);
   // const [url, setUrl] = useState('');
    const [listMenu, setListMenu] = useState([]);
    

    const navigate =useNavigate();


    const show_alerta = (msj,icon) =>{
        Swal.fire({ title:msj, icon:icon, buttonsStyling:true});
    }

    const mostrarMenu = async (id)=>{
        let res = '';
        await axios.get(endpointMenuClientes+id)
        .then((response)=>{
            console.log(response + 'respuesta 1');
            setListMenu(response.data);
        }).catch((errors) => {
            res = errors.response.data;
            show_alerta(res.msg, 'error');
        });
    }

    const obtenerUsuario = async (id) =>{
        let res = '';
        await axios.get(endpointObtenerUser+id)
        .then((response)=>{
            console.log(response);
            setName(response.data.name);
            setImagen(response.data.imagen);
            setWhats(response.data.whats);
        }).catch((errors) => {
            res = errors.response.data;
            show_alerta(res.msg, 'error');
        });
    }

    useEffect(()=>{
        const currentUrl = window.location.href;
        setUrlCliente(currentUrl);
        const urlParts = currentUrl.split('/');
        console.log('Esta es: ' + urlParts[2]);
        //alert(urlParts[urlParts.length-1]);
        mostrarMenu(urlParts[urlParts.length-1]);
        obtenerUsuario(urlParts[urlParts.length-1]);
    }, []);

    const agregarCompra = (cnt, prod)=>{
        let cantidad = document.getElementById(cnt).value;
        if(cantidad == ''){cantidad = 1}
        let numeroEntero =parseInt(cantidad, 10);
        prod.cantidad = numeroEntero;
        const newCart = [...cart, prod];
        setCart(newCart);
        console.log(newCart);
        
    }

    return(
    <>
        <Header3 mensaje={name} logo={imagen}/>
        <div className="f-menu">
            <div className="contenedorMenu">
                <div className="d-flex justify-content-around">
                   
                </div>
    
                {
                    listMenu.map((lmenu) => (
                        <div className="itemMenu" key={crypto.randomUUID().toString()}>
                            <div className="imgMenu">
                                <img src={"https://portafoliomars.xyz/storage/platillos/"+lmenu.imagen} alt="platillo" />
                            </div>
                            <div className="descPlato w-100">
                                <div className="w-100">
                                    <h5>{ lmenu.name }</h5>
                                    <p>{ lmenu.descripcion }</p>
                                    <h5>{'$ '+lmenu.precio }</h5>
                                </div>       
                            </div> 
                            <div className="w-100 d-flex justify-content-around">
                                <div className=" mb-3">
                                    <input type="number" className="form-control inputCantidad" id={"ct"+lmenu.id} min={1} max={5} placeholder="1" required/>
                                </div>
                                <button className="btnPedir" onClick={ () => agregarCompra("ct"+lmenu.id, lmenu) }><i className="bi bi-cart"></i></button> 
                            </div>
                                           
                        </div> 
                    ))
                }
             
            </div>
        </div>
    </>
    );
}

export default MenuClientes;