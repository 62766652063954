import React, { useContext, useEffect, useState } from "react";
import { productContext } from "../context/ProductContext";
import storage from "../storage/storage";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Header3 from "../components/Header3";
import '../App.css';
import '../menuEditable/menuEditable.css';
import Swal from "sweetalert2";


function Cart( props ){
    
    const {cart, setCart, imagen, name, setImagen, setName, urlCliente, deleteItemCart, sumaCart, suma, setSumaCart, whats} = useContext(productContext);
    var [sumatotal, setSumatotal] = useState(0);
    var mensaje = '';

   // const [url, setUrl] = useState('');

    const navigate =useNavigate();

    const show_alerta = (msj,icon) =>{
        Swal.fire({ title:msj, icon:icon, buttonsStyling:true});
    }

    const quitarCompra = (prod)=>{
        deleteItemCart(prod.id);
        const sumaPrice = cart.reduce((acc, curr) => acc + parseFloat(curr.precio * curr.cantidad), 0);
        setSumaCart(sumaPrice.toFixed(2));
        //alert(sumaPrice);
    }

    useEffect(()=>{
        setSumatotal(suma());
    }, [cart]);

    return(
    <>
        <Header3 mensaje={name} logo={imagen}/>
        <div className="f-menu">
            <div className="contenedorMenu">
                <div className="d-flex justify-content-around">
                   <h5 className="text-white">Tu pedido</h5>
                </div>
    
                {
                    cart.map((lmenu) => (
                        <div className="itemMenu" key={crypto.randomUUID().toString()}>
                            <div className="imgMenu">
                                <img src={"https://portafoliomars.xyz/storage/platillos/"+lmenu.imagen} alt="platillo" />
                            </div>
                            <div className="descPlato w-100">
                                <div className="w-100">
                                    <h5>{ lmenu.name }</h5>
                                    <p>{ lmenu.descripcion }</p>
                                    <h5>{'$ '+lmenu.precio }</h5>
                                </div>
                                {mensaje = mensaje + ' ' + lmenu.cantidad + ' ' + lmenu.name +' por un total de $' + (lmenu.precio * lmenu.cantidad).toFixed(2)}       
                            </div> 
                            <div className="w-100 d-flex justify-content-around">
                                <div className=" mb-3">
                                    <h5>{ lmenu.cantidad }</h5>
                                </div>
                                <button className="btnQuitar" onClick={ () => quitarCompra(lmenu) }><i class="bi bi-trash"></i></button> 
                            </div>
                                           
                        </div> 
                    ))
                }
                <div className="d-flex justify-content-between">
                    <div></div>
                    <h5 className="text-white">Total $ { sumatotal }</h5>
                    {sumatotal == 0 ? '' : (<a className="btnEnviar" href={"https://wa.me/" + whats + "?text=Hola! Quiero ordenar " + mensaje}>Enviar pedido</a>)}            
                </div>  
            </div>
                
        </div>
    </>
    );
}

export default Cart;