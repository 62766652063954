import React, { useContext, useEffect, useState} from "react";
import axios from "axios";
import { productContext } from "../context/ProductContext";
import storage from "../storage/storage";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import '../App.css';
import './profile.css';

function Editar(){
    const [email, setEmail] = useState('');
    const [nombre, setNombre] = useState('');
    const [whats, setWhats] = useState('');
    
    const [resp, setResp] = useState('');
    const navigate =useNavigate();
    const {endpointEditar, endpointMostrarUser} = useContext(productContext);
    const show_alerta = (msj,icon) =>{
        Swal.fire({ title:msj, icon:icon, buttonsStyling:true});
    }
    const mostrarDatos = async (e)=>{
        let res = '';
        await axios.get(endpointMostrarUser+`/${storage.get('idUser')}`, 
        {headers: {'Authorization' : 'Bearer ' + storage.get('authToken')}})
        .then((response)=>{
            console.log(response);
            setEmail(response.data.email);
            setNombre(response.data.name);
            setWhats(response.data.whats);
        }).catch((errors) => {
            res = errors.response.data;
            show_alerta(res.msg, 'error');
        });
    }
    useEffect(()=>{
        mostrarDatos();
    }, []);
    

    const guardar = async (e)=> {
        e.preventDefault();
        let revisarEnvio = email + nombre + whats;
        if(revisarEnvio == ''){
            show_alerta('Debes ingresar algun dato', 'error');
            return '';
        }
        let res = '';
        await axios.put(endpointEditar + '/' + storage.get('idUser'),
            {email:email, name:nombre, whats:whats},
            { headers: { 'Authorization' : 'Bearer ' + storage.get('authToken')}}
            )
            .then((response) => {
                console.log(response.data);
                setResp(response.data);
                show_alerta(response.data.msg, 'success');
                //navigate('/');
            }).catch((errors) => {
                res = errors.response.data;
                show_alerta(res.msg, 'error');
            });
    }
    return(
        <>
            <div className="servicios">
                <div className="sub-servicios">
                    <div className="mb-3">
                    </div>
                    <form className="w-100  p-3" onSubmit={guardar}>
                        <div className="form-floating mb-3">
                            <input type="email" className="form-control text-muted" id="email" value={email}  readOnly/>
                            <label >Email</label>
                        </div>
                        <div className="form-floating mb-3">
                            <input type="text" className="form-control" id="nombre" value={nombre} onChange= { (e)=> setNombre(e.target.value)}/>
                            <label >Nombre del Negocio</label>
                        </div>
                        <div className="form-floating mb-3">
                            <input type="phone" className="form-control" id="whatsapp" value={whats} onChange= { (e)=> setWhats(e.target.value)}/>
                            <label >WhatsApp</label>
                        </div>
                        <button className="btn btn-primary mt-3 px-4" type="submit">Guardar</button>
                    </form>
                </div>
            </div>
        </>
    );
}

export default Editar;