import React, {useContext} from "react";
import { productContext } from "../context/ProductContext";
import { Link } from "react-router-dom";
import '../estilos/Header.css';
import '../App.css';

function Header3( props ) {
    const {cart} = useContext(productContext);

    return(
        <header>
            <nav className="header3">
                <div className="">
                    <div className="fotoLogo mt-3">
                        <img src={"https://portafoliomars.xyz/storage/perfiles/"+props.logo} alt="logo"/>
                    </div>
                    <div className="text-white">{ props.mensaje }</div>               
                </div>
                <div className="contenedor-Cart d-flex align-items-center">
                    <div className="">
                        <div className="d-flex align-items-center">
                            <Link to={'/cart'} className="iconoCart">
                            
                                <span className={cart.reduce((acc, curr) => acc + curr.cantidad, 0) == 0 ? '' : 'ctCarrito'}>{cart.reduce((acc, curr) => acc + curr.cantidad, 0) == 0 ? '' : cart.reduce((acc, curr) => acc + curr.cantidad, 0)}</span>
                                <i className="bi bi-cart "></i>
                            </Link>                  
                        </div>
                    </div>
                </div>

            </nav>
        </header>
    );
}

export default Header3;