import React from "react";
import { Link } from "react-router-dom";
import '../App.css';
import './profile.css';

function Servicios(){
    return(
        <>
            <div className="servicios">
                <div className="sub-servicios">
                    <div className="mb-3">
                    </div>
                    <Link to={'/menu'} className="servicio">
                        <div className="servicio">
                            <i className="bi bi-list-columns text-white px-3 h3"></i><p>Menu editable e interactivo</p>
                        </div>
                    </Link>
                    
                    <div className="servicio">
                        <i className="bi bi-ticket-perforated text-white px-3 h3"></i> <p>Boleto electrónico</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Servicios;