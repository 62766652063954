import React, { useContext } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useState } from "react";
import { productContext } from "../context/ProductContext";
import Header2 from "../components/Header2";
import Swal from "sweetalert2";
import '../estilos/Header.css';
import '../App.css';

function Registro(){
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [password_confirm, setPasswordConfirm] = useState('');
    const [resp, setResp] = useState('');


    const {endpointRegister} = useContext(productContext);
    const show_alerta = (msj,icon) =>{
        Swal.fire({ title:msj, icon:icon, buttonsStyling:true});
    }


    const store = async (e)=> {
        e.preventDefault();
        let res = '';
        await axios.post(endpointRegister, 
            {name: name, email: email, password: password, password_confirmation: password_confirm})
            .then((response) => {
                console.log(response.data);
                setResp(response.data);
                show_alerta(response.data.msg, 'success');
            }).catch((errors) => {
                res = errors.response.data;
                show_alerta(res.msg, 'error');
            });
    }

    return (
        <>
        <Header2 mensaje={'registro'}/>
        <div className="container-fluid vh-100 fondo d-flex align-items-center justify-content-center text-center">
            <div className="container vh-100 text-center">
                <h2 className="mt-3 text-white">Registrarme</h2>
                <div className={resp.msg ? 'alert alert-success' : ''}><p>{resp.msg}</p></div>
                <form className="w-100  p-3" onSubmit={ store }>
                    <div className="form-floating mb-3">
                        <input type="text" className="form-control" id="name" value={name} onChange= { (e)=> setName(e.target.value)} required/>
                        <label >Nombre del negocio</label>
                    </div>
                    <div className="form-floating mb-3">
                        <input type="email" className="form-control" id="email" value={email} onChange= { (e)=> setEmail(e.target.value)} required/>
                        <label >Email</label>
                    </div>
                    <div className="form-floating mb-3">
                        <input type="password" className="form-control" id="password"  value={password} onChange= { (e)=> setPassword(e.target.value)} required/>
                        <label>Password</label>
                    </div>
                    <div className="form-floating">
                        <input type="password" className="form-control" id="confirm" value={password_confirm} onChange= { (e)=> setPasswordConfirm(e.target.value)} required/>
                        <label>Confirmar password</label>
                    </div>
                    <button className="btn btn-primary mt-3 px-4" type="submit">Registrarme</button>
                </form>
                <div className="arrow-left2">
                    <Link to='/' className="enlace">
                        <i className="bi bi-arrow-left text-white"> Atrás</i>
                    </Link> 
                </div>
                
            </div>
                   
        </div>
       </> 
    );
}

export default Registro;