import React, { useState } from "react";
import { productContext } from "./ProductContext";


const StateCompo = ( { children }) => {

    const endpoint = 'https://portafoliomars.xyz/api/login';
    const endpointLogout = 'https://portafoliomars.xyz/api/logout';
    const endpointRegister = 'https://portafoliomars.xyz/api/register';
    const endpointEditar = 'https://portafoliomars.xyz/api/update';
    const endpointMostrarUser = 'https://portafoliomars.xyz/api/mostrarUser';
    const endpointEditPass = 'https://portafoliomars.xyz/api/editPass';
    const endpointMostrarMenu = 'https://portafoliomars.xyz/api/menu';
    const endpointEditPlatillo = 'https://portafoliomars.xyz/api/editPlatillo';
    const endpointAgregarPlatillo = 'https://portafoliomars.xyz/api/agregarPlatillo';
    const endpointFotoPerfil = 'https://portafoliomars.xyz/api/fotoPerfil';
    const endpointVerFoto = 'https://portafoliomars.xyz/api/verFoto';
    const endpointMenuClientes = 'https://portafoliomars.xyz/api/menuClientes/';
    const endpointObtenerUser = 'https://portafoliomars.xyz/api/obtenerUser/';
    const endpointBorrarPlato = 'https://portafoliomars.xyz/api/destroy';



    
    const [datosPlatillo, setDatosPlatillo] =useState([]);
    const [cart, setCart] = useState([]);
    const [imagen, setImagen] = useState('');
    const [name, setName] = useState('');
    const [urlCliente, setUrlCliente] = useState('');
    const [sumaCart, setSumaCart] = useState(0);
    const [whats, setWhats] = useState(0);



    function suma(){
        let nuevoTotal = 0;
        nuevoTotal = cart.reduce((acc, curr) => acc + parseFloat(curr.precio * curr.cantidad), 0);
        setSumaCart(nuevoTotal);
        return nuevoTotal;
     }


    function deleteItemCart(id){
        const filtrados = cart.filter(item=> item.id !== id);
        console.log(filtrados);
        setCart(filtrados);
        setSumaCart(suma());
     }

     

    return (
        <productContext.Provider 
        value={{
            endpoint,
            endpointRegister,
            endpointLogout,
            endpointEditar,
            endpointMostrarUser ,
            endpointEditPass,
            endpointMostrarMenu,
            endpointEditPlatillo,
            datosPlatillo,
            setDatosPlatillo,
            endpointAgregarPlatillo,
            endpointFotoPerfil,
            endpointVerFoto,
            endpointMenuClientes,
            endpointObtenerUser,
            cart,
            setCart,
            imagen,
            name,
            setImagen,
            setName,
            urlCliente,
            setUrlCliente,
            deleteItemCart,
            sumaCart,
            setSumaCart,
            suma,
            whats,
            setWhats,
            endpointBorrarPlato
        }}
        >
                { children }
        </productContext.Provider>
    )
}

export default StateCompo;