import React, { useContext, useState} from "react";
import axios from "axios";
import { productContext } from "../context/ProductContext";
import storage from "../storage/storage";
import Swal from "sweetalert2";
import '../App.css';
import './profile.css';

function CambiarPass(){
    const [password, setPassword] = useState('');
    const [password_confirmation, setPasswordConfirmation] = useState('');
    const {endpointEditPass} = useContext(productContext);
    const show_alerta = (msj,icon) =>{
        Swal.fire({ title:msj, icon:icon, buttonsStyling:true});
    }


    const guardarPass = async (e) => {
        e.preventDefault();

        let res = '';
        await axios.put(endpointEditPass + '/' + storage.get('idUser'),
            {password:password, password_confirmation:password_confirmation},
            { headers: { 'Authorization' : 'Bearer ' + storage.get('authToken')}}
            )
            .then((response) => {
                console.log(response.data);
                if(response.data.status == 0){
                show_alerta(res.msg, 'error');

                }else{
                show_alerta(response.data.msg, 'success');
                }
                //navigate('/');
            }).catch((errors) => {
                res = errors.response.data;
                show_alerta(res.msg, 'error');
            });
    }

    return (
        <>
            <div className="servicios">
                <div className="sub-servicios">
                    <div className="mb-3">
                    </div>
                    <form className="w-100  p-3" onSubmit={guardarPass}>
                        <div className="form-floating mb-3">
                            <input type="password" className="form-control text-muted" id="email" value={password} onChange= { (e)=> setPassword(e.target.value)} required/>
                            <label >Password</label>
                        </div>
                        <div className="form-floating mb-3">
                            <input type="password" className="form-control" id="nombre" value={ password_confirmation } onChange= { (e)=> setPasswordConfirmation(e.target.value)} required/>
                            <label >Confirmación</label>
                        </div>
                        <button className="btn btn-primary mt-3 px-4" type="submit">Guardar</button>
                    </form>
                </div>
            </div>
        </>
    );
}

export default CambiarPass;