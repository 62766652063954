import React, {useContext} from "react";
import { productContext } from "../context/ProductContext";
import { Link, useNavigate } from "react-router-dom";
import storage from "../storage/storage";
import '../estilos/Header.css';
import '../App.css';
import axios from "axios";
import logoCweb from "./images/logo_cweb.png";



function Header() {

    const {endpointLogout} = useContext(productContext);
    const navigate =useNavigate();

    const logout = async() =>{
        await axios.get(endpointLogout, { headers: {'Authorization' : 'Bearer ' + storage.get('authToken')}}).then((response) =>{
           console.log(response.data);
           storage.clear();
           navigate('/'); 
        });
    }

    return(
        <header>
            <nav className="">
                <div className="navbar navPrincipal p-2">
                    <Link to={'/'}>
                        <img src={ logoCweb } className="logoCweb"/>
                    </Link>
                    <div className="text-white"><i className={storage.get('name') ? 'bi bi-person m-2' : ''}></i>{ storage.get('name') }</div>
                    <div className="d-flex h-100">
                        <Link to={'/iniciar-sesion'} className="enlace">
                            <div className={storage.get('name') ? '' : 'm-1'}>{ storage.get('name') ? '' :(<p className="i-Sesion">Iniciar Sesión</p>) }</div>
                        </Link>
                        <Link to={'/registrarme'} className="enlace">
                            <div className={storage.get('name') ? '' : 'm-1'}>{ storage.get('name') ? '' :(<p className="i-Sesion">Registrarme</p>) }</div>
                        </Link>
                        
                        <div className={storage.get('name') ? 'm-1' : ''} onClick={ logout }>{ storage.get('name') ? (<p className="i-Sesion">Cerrar Sesión</p>) : ''}</div>                  
                    </div>                 
                </div>

            </nav>
        </header>
    );
}

export default Header;